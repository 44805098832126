<template>
    <div class="minimenu">

        <div class="dids">
            <div class="header">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2 20.01L2.01 19.9989" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M15 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M2 16C4 16.5 5.5 18 6 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M2 12C6 12.5 9.5 16 10 20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00195 7.63027C5.00195 7.2989 5.27058 7.03027 5.60195 7.03027H18.4059C18.7372 7.03027 19.0059 7.2989 19.0059 7.63027V16.462C19.0059 16.7934 18.7372 17.062 18.4059 17.062H13.4413C13.2029 17.062 12.9876 16.9226 12.8854 16.7072C12.4625 15.815 11.2633 13.4465 9.81567 12C8.39582 10.5813 6.24338 9.55638 5.37987 9.17985C5.15312 9.08097 5.00195 8.85869 5.00195 8.61131V7.63027Z" fill="currentColor" /> </svg>
                <span> Devices </span>
            </div>

            <GameStream></GameStream>

            <div class="activeDid" v-if="activeDid">
                <div class="details" v-touch:touchhold="forceKill">
                    <p>
                        {{ activeDid.name }} </p>
                    <div>
                        <b> {{ activeDid.id }} </b>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6 17L3 17L3 4L21 4L21 17L18 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M8.62188 19.0672L11.5008 14.7488C11.7383 14.3926 12.2617 14.3926 12.4992 14.7488L15.3781 19.0672C15.6439 19.4659 15.3581 20 14.8789 20H9.12111C8.64189 20 8.35606 19.4659 8.62188 19.0672Z" stroke="currentColor" stroke-width="1.5" /> </svg>
                    </div>
                </div>

                <div class="actions">
                    <button @click="restart" v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" /> <path d="M16.5829 9.66667C15.8095 8.09697 14.043 7 11.9876 7C9.38854 7 7.25148 8.75408 7 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M14.4939 9.72222H16.4001C16.7315 9.72222 17.0001 9.45359 17.0001 9.12222V7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M7.41707 13.6667C8.19054 15.6288 9.95698 17 12.0124 17C14.6115 17 16.7485 14.8074 17 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M9.5061 13.6222H7.59992C7.26855 13.6222 6.99992 13.8909 6.99992 14.2222V16.4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                        Restart
                    </button>
                    <button @click="update" v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M12 13V22M12 22L15.5 18.5M12 22L8.5 18.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M20 17.6073C21.4937 17.0221 23 15.6889 23 13C23 9 19.6667 8 18 8C18 6 18 2 12 2C6 2 6 6 6 8C4.33333 8 1 9 1 13C1 15.6889 2.50628 17.0221 4 17.6073" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                        Update
                    </button>
                </div>

                <div class="adminBar">
                    <div v-for="(a, i) in admin" :key="i" @click="setAdmin(a)" :class="{ active: a.state }">
                        <div class="setting" v-ripple>
                            <svg v-if="a.setting === 'vpn'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5Z" fill="#fff" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M16 1C16 1 17.5 2 17.5 4C17.5 6 16 7 16 7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M8 1C8 1 6.5 2 6.5 4C6.5 6 8 7 8 7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M7 23L8.11111 19M17 23L15.8889 19M14.5 14L12 5L9.5 14M14.5 14H9.5M14.5 14L15.8889 19M9.5 14L8.11111 19M8.11111 19H15.8889" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                            <svg v-if="a.setting === 'bluetooth'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6.75 8L17.25 16.5L11.75 22V2L17.25 7.5L6.75 16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                            <svg v-if="a.setting === 'sunshine'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M2 20.01L2.01 19.9989" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M15 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M2 16C4 16.5 5.5 18 6 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M2 12C6 12.5 9.5 16 10 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                            <svg v-if="a.setting === 'hyperion'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M21 2L20 3" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M3 2L4 3" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M21 16L20 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M3 16L4 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M9 18H15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M10 21H14" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M11.9998 3C7.9997 3 5.95186 4.95029 5.99985 8C6.02324 9.48689 6.4997 10.5 7.49985 11.5C8.5 12.5 9 13 8.99985 15H14.9998C15 13.0001 15.5 12.5 16.4997 11.5001L16.4998 11.5C17.4997 10.5 17.9765 9.48689 17.9998 8C18.0478 4.95029 16 3 11.9998 3Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                            <span> {{ a.displayName || a.setting }} </span>
                            <div :class="{ state: a.state }" class="toggle">
                                <div class="ball"> </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="allDids">
                <div class="did" v-ripple v-for="(d, i) in dids" :key="i" @click="setDid(d.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M6 17L3 17L3 4L21 4L21 17L18 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M8.62188 19.0672L11.5008 14.7488C11.7383 14.3926 12.2617 14.3926 12.4992 14.7488L15.3781 19.0672C15.6439 19.4659 15.3581 20 14.8789 20H9.12111C8.64189 20 8.35606 19.4659 8.62188 19.0672Z" stroke="currentColor" stroke-width="1.5" /> </svg>
                    <p> {{ d.name }} </p>
                    <b> {{ d.id }} </b>
                </div>
            </div>
        </div>

        <li class="refresh">
            <div class="rdbuttn" @click="refreshData" v-ripple>
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M4 13V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path d="M12 3L12 15M12 15L8.5 11.5M12 15L15.5 11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                    Refresh Data
                </button>
            </div>

            <div class="slider" @click="() => { isHS = !isHS }" :class="{ hs: isHS }" v-ripple>
                <span class="ts"> TS </span>
                <span class="hs"> HLS </span>
                <div class="slide">

                </div>
            </div>
        </li>


        <li class="status">
            <template v-if="connected"> Connected </template>
            <template v-if="!connected"> Disconnected </template>
            <i class="indicator" :class="{ connected: connected }" />
        </li>
    </div>
</template>

<script>
import GameStream from '../components/GameStream.vue';


export default {
    name: 'Devices',

    components: {
        GameStream
    },

    data: function () {
        return {
            activeSection: 'live',
            dids: [],
            alldids: [],
            admin: [],
            activeDid: null,
            connected: false,
            searchOpen: false,
            menuOpen: false,
            open: false,
            isHS: false,
        }
    },

    methods: {
        forceKill() {
            fetch(process.env.VUE_APP_SERVER + `/forceKill?did=${window.did}`)
        },

        updateAdmin(admin) {
            this.admin = admin.sort((a, b) => {
                const nameA = a.setting.toLowerCase();
                const nameB = b.setting.toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;

            })
        },

        getAdmin() {
            fetch(process.env.VUE_APP_SERVER + '/admin?did=' + window.did).then(response => response.json()).then((data) => {
                this.updateAdmin(data.admin)
            })
        },

        setDid(did) {
            if (!did) { return }
            this.$root.$emit('newDid', did)
        },

        isActive(section) {
            if (!this.$route || !this.$route.name) { return false }
            return this.$route.name.includes(section)
        },


        refreshData() {
            fetch(process.env.VUE_APP_SERVER + '/refresh?mode=' + (this.isHS ? 'hls' : 'ts'))
            this.menuOpen = false
        },

        debug() {
            fetch(process.env.VUE_APP_SERVER + '/debug?did=' + window.did)
        },

        async setAdmin(admin) {
            const request = await fetch(process.env.VUE_APP_SERVER + `/setAdmin?did=${window.did}`, { method: 'POST', body: JSON.stringify(admin), headers: { "Content-Type": "application/json" } })
            let data = await request.json()
            this.updateAdmin(data.admin)
        },

        update() {
            var r = confirm("Are you sure you want to update?");
            if (r == true) {
                fetch(process.env.VUE_APP_SERVER + '/update?did=' + window.did)
                this.menuOpen = false
            }
        },
        restart() {
            var r = confirm("Are you sure you want to restart?");
            if (r == true) {
                fetch(process.env.VUE_APP_SERVER + '/restart?did=' + window.did)
                this.menuOpen = false
            }
        },
    },

    beforeDestroy: function () {
    },

    mounted: function () {
        this.$root.$on('newDidSet', (did) => {
            this.activeDid = this.dids.find((x) => { return x.id === did })
            this.getAdmin()
        })

        fetch(process.env.VUE_APP_SERVER + '/health').then(response => response.json()).then((data) => { this.connected = data.active })
        fetch(process.env.VUE_APP_SERVER + `/dids?qb=${window.qb}`).then(response => response.json()).then((data) => {
            this.alldids = data.dids
            this.dids = data.dids

            this.$nextTick(() => { this.setDid(window.did) })

        })
    }
}
</script>

<style scoped lang="scss">
.minimenu {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 5px;

    li {
        text-decoration: none;
        padding-bottom: 10px;
        margin: 10px;
        font-size: 9px;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &.status {
            position: absolute;
            top: 24px;
            right: 20px;
            text-transform: uppercase;

            .indicator {
                width: 5px;
                margin-left: 10px;
                height: 5px;
                background-color: red;
                border-radius: 50%;

                &.connected {
                    background-color: green;
                }

            }
        }
    }

    svg {
        padding-right: 10px;
    }
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.activeDid {
    width: calc(100% - 40px);
    margin: 0 auto;
    color: white;
    padding: 0 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    border-bottom: 1px solid rgba(black, 0.2);
    padding-bottom: 20px;
    margin-bottom: 20px;

    .actions {
        width: 100%;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        button {
            display: inline-block;
            height: 50%;
            font-size: 9px;
            max-width: 100%;
            min-width: 48.5%;
            background: unset;
            color: white;
            border: 1px solid white;
            border-radius: 5px;
            opacity: 0.75;
            padding: 5px 0;
            text-transform: uppercase;


            svg {
                width: 15px;
                vertical-align: middle;
            }
        }
    }

    .details {
        width: 100%;
        padding: 10px;
        padding-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 0;

        small {
            position: absolute;
            top: -2px;
            font-size: 7px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        >div {
            display: flex;
            align-items: center;
        }

        p {
            flex-grow: 1;
            font-size: 18px;
        }

        b {
            font-size: 9px;
            font-weight: bold;
        }

        svg {
            width: 15px;
            padding-left: 10px;
            padding-right: 0px;
            height: 20px;
            top: 0px;
            left: 0px;
        }
    }
}

.allDids {
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .did {
        border-radius: 5px;
        width: calc(50% - 25px);
        padding: 25px 10px;
        border: 1px solid rgba(0, 0, 0, 0.75);
        margin-bottom: 5px;
    }
}

.adminBar {
    display: flex;
    position: relative;
    flex-direction: column;
    top: 0;
    right: 0;
    margin: 0px;

    >div {
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .setting {
            flex-grow: 1;
            display: flex;
            align-items: center;
            margin: 5px 0;
            padding: 5px 0;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 9px;

            span {
                flex-grow: 1;
            }

            svg {
                padding-right: 5px !important;
                margin-right: 20px;
                padding: 5px;

            }

            .toggle {
                width: 50px;
                height: 25px;
                border: 1px solid white;
                position: relative;
                border-radius: 20px;
                ;
                opacity: 0.5;

                .ball {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 2.5px;
                    margin: 0 2.5px;
                    left: 0;
                    border-radius: 50%;
                    background-color: white;
                }

                &.state {
                    opacity: 1;

                    .ball {
                        transform: translateX(24px)
                    }

                }
            }

        }
    }
}

.refresh {
    background-color: rgba(black, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin: 10px 20px !important;
    padding-bottom: 0 !important;

    .slider {
        border: 1px solid white;
        padding: 12.5px 0;
        border-radius: 5px;

        span {
            padding: 10px;
            margin: 0 2.5px;
            border-radius: 5px;
            text-align: center;

            &.ts {
                background-color: white;
                color: black;

            }
        }

        &.hs {
            span {
                background-color: unset;
                color: white
            }

            span.hs {
                background-color: white;
                color: black;
            }

        }
    }

    .rdbuttn {
        flex-grow: 1;

        button {
            background-color: rgba(black, 0.4);
            color: white;
            border: none;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            font-size: 10px;
            text-transform: uppercase;
            border-radius: 5px;

            svg {
                width: 20px;
                padding-right: 15px;

            }
        }

    }
}</style>
